<!--
  - Copyright (C) 2020. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <b-container class="py-4" fluid>
    <h1>404</h1>
    <h2>NOT FOUND!</h2>
  </b-container>
</template>

<script>
  export default {
    name: 'NotFound'
  }
</script>

<style scoped>

</style>
